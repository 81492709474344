import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/firebase.config'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            redirect: 'login',
        },
        {
            path: '/accueil',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/blogs',
            name: 'blogs',
            component: () => import('@/views/Blogs/Blogs.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/blog/:id/ajouter',
            name: 'blog-write-article',
            component: () => import('@/views/Blogs/CreatePost.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/blog/:id/automatisation',
            name: 'blog-automation-article',
            component: () => import('@/views/Blogs/Automation/BlogAutomation.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

    if (requiresAuth && !auth.currentUser) {
        next({ name: 'login' })
    } else {
        next()
    }
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
