import Vue from 'vue'
// import firebase from 'firebase'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueCookies from 'vue-cookies'
import LogRocket from 'logrocket'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { auth, database } from './firebase.config'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/sweet-alerts'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueCookies)
Vue.$cookies.config('14d')
// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// const firebaseConfig = {
//     apiKey: 'AIzaSyAMFkeElKOG8nSBMm9CESGdkq9n0Fepezc',
//     authDomain: 'blogily.firebaseapp.com',
//     projectId: 'blogily',
//     storageBucket: 'blogily.appspot.com',
//     messagingSenderId: '697304039250',
//     appId: '1:697304039250:web:dd0a09db7efaefd805848b',
// }

// firebase.initializeApp(firebaseConfig)

LogRocket.init('9r8f8t/blogily')

auth.onAuthStateChanged(user => {
	store.dispatch('setUser', user)
	// eslint-disable-next-line no-unused-vars
	const app = new Vue({
		router,
		store,
		created() {
			console.log(this, 'main', auth, database)
		},
		render: h => h(App),
	}).$mount('#app')
	Vue.prototype.apiUrl = 'http://127.0.0.1:8001'
	// Vue.prototype.apiUrl = 'https://backend-blogily.contana.fr'

	Vue.prototype.notify = (title, type = 'success', message = '') => {
		app.$toast({
			component: ToastificationContent,
			position: 'top-right',
			props: {
				title,
				icon: type === 'success' ? 'CheckCircleIcon' : 'AlertTriangleIcon',
				variant: type,
				text: message,
			},
		})
	}
})
