export default {
	state: {
		user: {
			loggedIn: false,
			data: null,
		},
		blogs: [],
	},
	getters: {
		user(state) {
			return state.user
		},
		blogs(state) {
			return state.blogs
		},
	},
	mutations: {
		SET_LOGGED_IN(state, value) {
			state.user.loggedIn = value
		},
		SET_USER(state, data) {
			state.user.data = data
		},
		blogs(state, blogs) {
			state.blogs = blogs
		},
	},
	actions: {
		setUser({ commit }, user) {
			commit('SET_LOGGED_IN', user !== null)

			if (user) {
				commit('SET_USER', {
					displayName: user.displayName,
					email: user.email,
				})
			} else {
				commit('SET_USER', null)
			}
		},
	},
}
