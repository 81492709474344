import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: 'AIzaSyAMFkeElKOG8nSBMm9CESGdkq9n0Fepezc',
    authDomain: 'blogily.firebaseapp.com',
    projectId: 'blogily',
    storageBucket: 'blogily.appspot.com',
    messagingSenderId: '697304039250',
    appId: '1:697304039250:web:dd0a09db7efaefd805848b',
}

firebase.initializeApp(firebaseConfig)

const database = firebase.firestore()
const auth = firebase.auth()
// eslint-disable-next-line prefer-destructuring
const firestore = firebase.firestore

export { database, auth, firestore }
